import { render } from "./BarcodeImport.vue?vue&type=template&id=b1a3f81c&scoped=true"
import script from "./BarcodeImport.vue?vue&type=script&lang=ts"
export * from "./BarcodeImport.vue?vue&type=script&lang=ts"

import "./BarcodeImport.vue?vue&type=style&index=0&id=b1a3f81c&lang=css"
import "./BarcodeImport.vue?vue&type=style&index=1&id=b1a3f81c&lang=scss"
import "./BarcodeImport.vue?vue&type=style&index=2&id=b1a3f81c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-b1a3f81c"

export default script