<template>
  <div v-loading.fullscreen="loading" class="flex flex-col barcode-import fixed-table">
    <div class="header-container-wrapper">
      <div class="items-center pb-8 mb-8 border-solid border-b-1 border-gray-300 header-container">
        <div class="flex mx--2 flex-wrap barcode-group-header">
          <div class="flex px-2px header-container__left">
            <PageHeader :title="$t('barcodeImport.barcodeImport')" @load:refreshList="refreshList" />
            <div class="flex px-2px items-center">
              <el-select
                key="projects"
                :class="'w-1/2 max-w-xs mr-4 ml-4'"
                v-model="dataSearch.projectCode"
                filterable
                @change="onSelectProject"
                :placeholder="$t('selectOne')"
              >
                <el-option v-for="(item, key) in getProjectsList" :key="key" :label="item.name" :value="item.code">
                  {{ item.name }}
                </el-option>
              </el-select>
              <el-select
                @change="onVersionChange"
                v-model="dataSearch.version"
                :placeholder="$t('projects.version')"
                style="width: 92px"
              >
                <el-option v-for="index in maxProjectVersion" :key="index" :label="index" :value="index">
                  {{ index }}
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="flex flex-grow px-2px justify-end header-container__right"></div>
        </div>
      </div>
    </div>
    <h4 v-if="getItems?.some((val) => isWarningRow(val))" class="text-sm text-red-500 items-center flex mr-4 py-2">
      * Warning items
    </h4>
    <div class="flex items-center justify-end my-2">
      <el-button
        type="default"
        class="bg-dark-min text-white focus:text-white rounded-md ml-4"
        size="small"
        @click="showBulkPublishDialog()"
      >
        <div class="flex items-center">
          <IconBulkBarcode />
          <span class="ml-1">{{ $t('barcodeImport.bulkBarcode') }}</span>
        </div>
      </el-button>
      <el-button
        type="default"
        class="bg-blue-dark-min text-white focus:text-white rounded-md"
        @click="isShowCSVImportDialog = true"
      >
        <div class="flex items-center">
          <IconDownload />
          <span class="ml-2">{{ $t('barcodeImport.csv_import') }}</span>
        </div>
      </el-button>
    </div>
    <div v-if="getItems.length > 0" class="flex flex-col">
      <div class="mb-16 table-container">
        <el-table
          :data="getItems"
          class="table-content rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          :cell-class-name="tableCellClassName"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @cell-click="onEditBarcode"
        >
          <el-table-column prop="operationType" :label="$t('barcodeImport.operation')" :min-width="19">
            <template v-slot="scope">
              <span class="uppercase">
                {{ scope?.row?.operationType ? $t(`barcodeImport.${scope.row.operationType}`) : '' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('barcodeImport.progress')" :min-width="17">
            <template v-slot="scope">{{ translatedProgress(scope?.row?.status, scope?.row) }}</template>
          </el-table-column>
          <el-table-column prop="barcodeTypeDefinition?.name" :label="$t('projects.barcodeType')" :min-width="40">
            <template v-slot="scope">
              <div class="flex" v-if="scope.row.barcodeTypeDefinition">
                <div class="w-8 h-8 barcode-icon-container flex item-centers">
                  <BarcodeIcon
                    :barcodeType="formatBarcodeType(scope.row.barcodeTypeDefinition)"
                    :isShowLabel="false"
                    :isPointer="false"
                  />
                </div>
                <div class="flex-grow flex items-center ml-4">{{ scope.row.barcodeTypeDefinition.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="totalCount" :label="$t('barcodeImport.totalNumber')" :min-width="16">
            <template v-slot="scope">{{ scope.row.totalCount }}</template>
          </el-table-column>
          <el-table-column prop="errorCount" :label="$t('barcodeImport.errorNumber')" :min-width="16">
            <template v-slot="scope">{{ scope.row.errorCount }}</template>
          </el-table-column>
          <el-table-column prop="audit.createdDateTime" :label="$t('barcode.createdDate')" :min-width="22">
            <template v-slot="scope">{{ displayDateTime(scope.row.audit?.createdDateTime) }}</template>
          </el-table-column>
          <el-table-column prop="audit.processedDateTime" :label="$t('barcodeImport.completedDate')" :min-width="22">
            <template v-slot="scope">{{ displayDateTime(scope.row.audit.processedDateTime) }}</template>
          </el-table-column>
          <el-table-column :prop="canNotClickColumn" width="90">
            <template v-slot="scope">
              <a
                v-if="scope.row.status === completedStatus"
                class="flex justify-center"
                @click="showExportDialog(scope.row?.id, scope.row?.barcodeType)"
              >
                <IconPDF />
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="getTotalBarcode"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        />
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>

    <!--====================== BULK PUBLISH DIALOG ================================-->
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowDialog"
      width="540px"
      top="0"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <template #title>
        <span> {{ $t('barcodeImport.bulk_publish') }}</span>
      </template>
      <div class="px-6 py-4 flex items-center">
        <span class="w-40 flex justify-end mr-4"> {{ $t('Projects') }}</span>
        <span> {{ projectName }}</span>
      </div>
      <div class="px-6 py-4 flex items-center">
        <span class="w-40 flex justify-end mr-4"> {{ $t('barcodeImport.barcode_amount') }}</span>
        <div>
          <InputOnlyNumber
            v-model="barcodeAmount"
            :min="1"
            :max="10000"
            :size="'small'"
            classes="text-center"
            :controls="true"
            :precision="0"
          />
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="isShowDialog = false">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="onBulkPublish">
              {{ $t('timetable.process') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>

    <BarcodePDF
      ref="barcodePDFComponent"
      :key="barcodeImportId"
      :barcodes="barcodeExports"
      :project="getProject"
      :barcodeType="selectedBarcodeType"
      :operationType="barcodeImportOperationType"
      @done:exportPDF="exportPDFDone"
    />

    <!-- ==================================== CSV IMPORT ========================================= -->
    <div class="dialog-area">
      <el-dialog
        custom-class="el-dialog--custom"
        v-model="isShowCSVImportDialog"
        width="500px"
        top="0"
        :title="$t('barcodeImport.csv_import')"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="true"
        @close="isShowCSVImportDialog = false"
      >
        <div class="my-4">
          <!-- Choose barcode types -->
          <div class="my-4">
            <div class="font-bold">1. {{ $t('barcodeImport.select_target_barcode_types') }}</div>
            <div class="my-3 mx-4">
              <el-select
                class="self-center barcode-selection"
                key="barcodes"
                v-model="barcodeType"
                :placeholder="$t('selectOne')"
              >
                <el-option v-for="(item, key) in getBarcodes" :key="key" :label="item.name" :value="key">
                  {{ item.name }}
                </el-option>
              </el-select>
            </div>
          </div>

          <el-form :disabled="barcodeType === ''">
            <div class="my-4">
              <div class="font-bold">2. {{ $t('barcodeImport.setup_import_rules') }}</div>
              <div class="my-4 mx-2">
                <div class="my-3">
                  <el-radio-group v-model="operationTypes" @change="onImportTypeChanged">
                    <el-radio :value="'fileImportNew'" :label="'fileImportNew'">
                      {{ $t('barcodeImport.new_import') }}
                    </el-radio>
                    <el-radio :value="'fileImportUpdate'" :label="'fileImportUpdate'">
                      {{ $t('barcodeImport.update') }}
                    </el-radio>
                    <el-radio v-if="isTradeWaltzEnable" :value="'tradewaltz'" :label="'tradewaltz'">
                      {{ $t('barcodeImport.tradewaltz') }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="my-3">
                  <el-checkbox v-model="isFailedIfHaveError">
                    {{ $t('barcodeImport.if_error_mark_all_error') }}
                  </el-checkbox>
                </div>
                <div v-if="isSetDisplayedBarcodeByClient" class="my-1">
                  {{ $t('barcodeImport.assign_client') }}
                  <el-button type="default" class="mx-2" @click="isShowClients = true"> {{ $t('setting') }}</el-button>
                </div>
              </div>
            </div>
            <template v-if="operationTypes === 'tradewaltz'">
              <div class="font-bold">3. {{ $t('barcodeImport.tradewaltz_setting') }}</div>
              <div class="my-2 mx-4"><TWImportView /></div>
            </template>
            <template v-else>
              <div class="my-4">
                <div class="font-bold">3. {{ $t('barcodeImport.download_csv_file_template') }}</div>
                <div class="my-4">
                  <span class="p-6 cursor-pointer text-blue-600" @click="downloadCSV">
                    {{ $t('barcodeImport.download_csv_file') }}
                  </span>
                </div>
              </div>
              <div class="my-6">
                <div class="font-bold">4. {{ $t('barcodeImport.upload_csv_file') }}</div>
                <div class="my-2 mx-4">
                  <!-- ($event.raw) -->
                  <el-upload
                    class="upload-demo"
                    ref="bulkCsvInput"
                    accept="text/csv"
                    :on-change="onCsvFilePicked"
                    :auto-upload="false"
                    drag
                    action="#"
                  >
                    <div class="m-10">
                      <el-icon class="el-icon--upload"><upload-filled class="w-10 h-10"/></el-icon>
                      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    </div>

                    <template #tip>
                      <div class="el-upload__tip">
                        CSV files with a size less than 10.5MB
                      </div>
                    </template>
                  </el-upload>
                </div>
              </div>
            </template>
          </el-form>
        </div>
        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button type="default" class="btn-default-cancel" @click="isShowCSVImportDialog = false">
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="onProcess">
                {{ $t('barcode_type.upload') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>

    <div v-if="isSetDisplayedBarcodeByClient" class="client-dialog-area">
      <el-dialog
        custom-class="el-dialog--custom"
        v-model="isShowClients"
        width="1000px"
        top="0"
        :title="$t('set_client_publish_barcode')"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="true"
        @close="isShowClients = false"
      >
        <div>
          {{ $t('select_clients_to_publish_barcode') }}
        </div>
        <div class="my-4">
          <el-checkbox-group v-model="checkedClientIds">
            <div class="flex flex-row flex-wrap px-2 group-name-container">
              <div v-for="client in clients" :key="client.id" class="w-1/4 py-2">
                <el-checkbox :value="client.id" :label="client.id">
                  {{ client.name }}
                </el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button type="default" class="btn-default-cancel" @click="isShowClients = false">
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="setAssignedClientIds()">
                {{ $t('set') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import BarcodePDF from '@/components/barcodeImport/BarcodePDF.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconBulkBarcode from '@/components/svg/IconBulkBarcode.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import IconPDF from '@/components/svg/IconPDF.vue'
import IconUpload from '@/components/svg/IconUpload.vue'
import {
  FETCH_ALL_TW_PROCESS,
  LOAD_BARCODE_IMPORT_LIST,
  LOAD_BARCODE_IMPORT_PROJECT,
  LOAD_CLIENT_LIST,
  LOAD_PROJECTS_LIST,
} from '@/store/actions'
import { bulkPublish, getBarcodeCSVExample, getBarcodeImportDetail, uploadBarcodeImportCSV } from '@/utils/api'
import { BARCODE_IMPORT_LAYOUT_PDF_EXPORT, OPERATION_TYPE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { saveCSVText } from '@/utils/exportPdf'
import { openMessage } from '@/utils/utils'
import TWImportView from '@/views/tradeWaltz/TWImportView.vue'
import { UploadFilled } from '@element-plus/icons'
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import { EBarcodePublishStatus } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeCreationResult,
  IBarcodeDefinitionType,
  IBarcodeImportDetail,
  IBarcodeImportList,
  IBarcodeImportRow,
  IBarcodeImportSearch,
  IClientList,
  IProject,
  ITWProcess,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, ProvideReactive, Watch } from 'vue-property-decorator'

export interface IClient {
  id: string
  name: string
  email: string
}
@Options({
  components: {
    PageHeader,
    ItemNotFound,
    BarcodeIcon,
    IconBulkBarcode,
    IconUpload,
    IconDownload,
    IconPDF,
    BarcodePDF,
    InputOnlyNumber,
    UploadFilled,
    TWImportView,
  },
  name: 'BarcodeImport',
})
export default class BarcodeImport extends Mixins(DataTableMixin) {
  @ProvideReactive() projectVersion = 0
  @ProvideReactive() barcodeType = ''
  @ProvideReactive() dataSearch = {} as IBarcodeImportSearch
  operationTypes: 'fileImportNew' | 'fileImportUpdate' | 'tradewaltz' = 'fileImportNew'
  barcodeAmount = 1
  isShowDialog = false
  defaultSortKey = 'status'
  canNotClickColumn = 'pdfColumn'
  barcodeImportId = ''
  barcodeExports = [] as IBarcodeCreationResult[]
  barcodeImportOperationType = ''
  loading = false
  selectedBarcodeType = ''
  isShowTradeWaltzDialog = false
  assignedClientIds = [] as string[]
  checkedClientIds = []
  isShowClients = false
  isShowCSVImportDialog = false

  isFailedIfHaveError = true

  tableCellClassName(object: IBarcodeImportRow) {
    if (object.row.status === EBarcodePublishStatus.COMPLETED) {
      if (object.row.errorCount > 0) {
        return 'warning-row custom-table__cell cursor-pointer'
      }

      if (object.columnIndex === 0) {
        return 'completed-row'
      }
      return 'custom-table__cell cursor-pointer'
    } else if (object.row.status === EBarcodePublishStatus.ERROR) {
      return 'error-row custom-table__cell cursor-pointer'
    } else {
      return 'custom-table__cell cursor-pointer'
    }
  }

  translatedProgress(val: string, row: IBarcodeImportList) {
    if (!val) return ''
    return `${this.$t(`barcode_status.${val}`)}${this.isWarningRow(row) ? '*' : ''}`
  }

  isWarningRow(val: IBarcodeImportList) {
    return val?.status === EBarcodePublishStatus.COMPLETED && val?.errorCount > 0
  }

  get getItems(): IBarcodeImportList[] {
    return this.$store.state.barcodeImport?.barcodeImports
  }

  get getTotalBarcode(): number {
    return this.$store.state.barcodeImport?.totalBarcodes
  }

  get getProject(): IProject {
    return this.$store.state.barcodeImport?.projectList
  }

  get getBarcodes(): Record<string, IBarcodeDefinitionType> {
    return this.getProject?.barcodeTypes || {}
  }

  get clients(): IClient[] {
    return ([...this.$store.state.client.clients]
      .filter((val: IClientList) => !!val.clientUser)
      .map((c: IClientList) => ({
        id: c.clientUser.id,
        name: c.client.name,
        email: c.clientUser.email,
      })) ?? []) as IClient[]
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get completedStatus(): string {
    return EBarcodePublishStatus.COMPLETED
  }

  get barcodeImportLayouts() {
    const result = {} as Record<number, string>
    for (const key in BARCODE_IMPORT_LAYOUT_PDF_EXPORT) {
      result[BARCODE_IMPORT_LAYOUT_PDF_EXPORT[key]] = this.$t(`barcodeImport.${key}`)
    }
    return result
  }

  formatBarcodeType(barcodeTypeDefinition: IBarcodeDefinitionType): IBarcodeDefinitionType {
    return barcodeTypeDefinition
  }

  exportPDFDone() {
    this.barcodeExports = []
  }

  async showExportDialog(barcodeImportId: string, barcodeType: string) {
    this.barcodeImportId = barcodeImportId
    this.barcodeExports = []

    try {
      this.loading = true
      const data = (await getBarcodeImportDetail(barcodeImportId)) || ({} as IBarcodeImportDetail)
      if (data && data.results.length > 0) {
        const existBarcodeImport = (this.getItems ?? []).find(
          (item) => item.id === barcodeImportId
        ) as IBarcodeImportList
        this.barcodeImportOperationType = existBarcodeImport.operationType
        this.projectVersion = Number(existBarcodeImport.projectVersion || 0)
        this.barcodeExports = data.results
        this.selectedBarcodeType = barcodeType || ''
        this.$refs.barcodePDFComponent.showComfirmPopup()
        window.scrollTo(0, 0)
      } else {
        openMessage(this.$t('errors.2303'), 'error')
      }
    } catch (error) {
      openMessage(error as string, 'error')
    } finally {
      this.loading = false
    }
  }

  @Watch('barcodeExports')
  hideExportPDFDialog() {
    if (this.barcodeExports.length === 0) {
      this.barcodeImportId = ''
    }
  }

  async onImportTypeChanged(val: string) {
    if (val === 'tradewaltz') {
      try {
        const processesData: ITWProcess[] = this.$store.state.tradeWaltz.processesData
        if (!processesData || processesData.length < 1) {
          this.isLoading = true
        }
        await this.$store.dispatch(FETCH_ALL_TW_PROCESS)
      } catch (error) {
        errorHandler(error)
      } finally {
        this.isLoading = false
      }
    }
  }

  async fetchBarcodesList(data: IBarcodeImportSearch, isLoad = true) {
    const newData = {
      projectCode: data.projectCode,
      version: data.version,
      skip: (this.currentPageNum - 1) * this.itemsPerPage,
      count: this.itemsPerPage,
      t: this.$t,
      te: this.$te,
    }
    if (isLoad) this.redirectIndex()

    this.loading = true
    await this.$store.dispatch(LOAD_BARCODE_IMPORT_LIST, newData).finally(() => (this.loading = false))
  }

  get getProjectsList(): IProject[] {
    return this.$store.state.project?.originalProjects.filter((item: IProject) => {
      return !(item.isDraft && item.isDraft === true)
    })
  }

  get maxProjectVersion() {
    return this.getProjectsList.find((val) => val?.code === this.dataSearch?.projectCode)?.version ?? 1
  }

  get projectName() {
    return this.getProjectsList.find((val) => val?.code === this.dataSearch?.projectCode)?.name ?? ''
  }

  get isTradeWaltzEnable() {
    return this.$store.getters.isTradeWaltzPermission
  }

  async onSelectProject() {
    this.barcodeType = ''
    this.currentPageNum = 1
    this.getLastestVersion(this.dataSearch.projectCode)
    await this.fetchBarcodesList(this.dataSearch)
  }

  async onVersionChange() {
    const newData = {
      projectCode: this.dataSearch.projectCode,
      version: this.dataSearch.version,
      t: this.$t,
      te: this.$te,
    }
    this.redirectIndex()
    await this.$store.dispatch(LOAD_BARCODE_IMPORT_PROJECT, newData).finally(() => (this.loading = false))
  }

  onEditBarcode(barcode: IBarcodeImportList, column: Record<string, unknown>) {
    if (
      column.property === this.canNotClickColumn ||
      barcode.operationType === OPERATION_TYPE.BULK_CREATE ||
      barcode.status === EBarcodePublishStatus.REQUESTED ||
      barcode.status === EBarcodePublishStatus.PROGRESS
    ) {
      return
    }

    this.$router.push({
      name: 'barcodeImportDetail',
      params: { id: barcode.id, projectCode: this.dataSearch?.projectCode },
    })
  }

  async refreshList() {
    await this.fetchBarcodesList(this.dataSearch, false)
  }

  async created() {
    this.routeName = 'barcodePublish'
    await this.fetchClients()
    this.getQueryData()

    this.isSort = true

    if (!this.$store.state.project?.isLoaded) {
      this.loading = true
      await this.$store.dispatch(LOAD_PROJECTS_LIST).finally(() => (this.loading = false))
    }

    if (!this.dataSearch.projectCode) {
      this.dataSearch.projectCode = this.getProjectsList?.[0].code ?? ''
    }

    if (!this.dataSearch.version) {
      this.getLastestVersion(this.dataSearch.projectCode)
    }
    await this.fetchBarcodesList(this.dataSearch)
  }

  getLastestVersion(projectCode: string) {
    const chooseProject = this.getProjectsList.find((item) => item.code === projectCode)
    this.dataSearch.version = chooseProject?.version ?? 1
  }

  showBulkPublishDialog() {
    this.isShowDialog = true
  }

  async fetchClients() {
    if (!this.isSetDisplayedBarcodeByClient) return
    if (this.clients.length > 0) return
    try {
      this.loading = true
      await this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async onBulkPublish() {
    const projectId = this.getProject?.id || ''
    try {
      this.loading = true
      await bulkPublish(projectId, this.barcodeAmount, this.assignedClientIds)
      openMessage(this.$t('publish_successful'), 'success')
      await this.fetchBarcodesList(this.dataSearch, false)

      this.isShowDialog = false
    } catch (error) {
      openMessage(error as string, 'error')
    } finally {
      this.loading = false
    }
  }

  selectedCSVFile: UploadFile | null = null

  async onProcess() {
    if (!this.selectedCSVFile) return
    try {
      this.loading = true
      const projectId = this.getProject?.id || ''
      const barcodeType = this.barcodeType || ''
      const formData = new FormData()

      formData.append('file', this.selectedCSVFile.raw)
      formData.append('projectId', projectId)
      formData.append('barcodeType', barcodeType)
      formData.append('operationType', this.operationTypes)
      this.assignedClientIds.forEach((element) => formData.append('clientIds[]', element))
      formData.append('isSkipError', (!this.isFailedIfHaveError).toString())

      await uploadBarcodeImportCSV(formData, () => false)
      openMessage(this.$t('publish_successful'), 'success')
      await this.fetchBarcodesList(this.dataSearch)
    } catch (error) {
      if (
        !!error &&
        typeof error === 'object' &&
        'File' in error &&
        error.File !== undefined &&
        Array.isArray(error.File)
      ) {
        const errCode = error.File?.[0] ?? ''
        if (errCode.includes('1015')) {
          const limit = errCode.substring(errCode.indexOf('('), errCode.indexOf(')'))
          openMessage(this.$t('errors.1015', { val1: limit }), 'error')
        }
      } else {
        openMessage(error as string, 'error')
      }
    } finally {
      this.loading = false
    }

    this.$refs.bulkCsvInput.value = null
    this.isShowCSVImportDialog = false
  }

  async onCsvFilePicked(firstCsvFile: UploadFile) {
    this.selectedCSVFile = firstCsvFile
  }

  uploadNewCSV(operationType: 'fileImportNew' | 'fileImportUpdate' | 'tradewaltz') {
    const projectId = this.getProject?.id || ''
    const barcodeType = this.barcodeType || ''
    if (!projectId || !barcodeType) {
      openMessage(this.$t('barcodeImport.please_select_type'), 'error')
    } else {
      this.operationTypes = operationType || ''
      this.$refs.bulkCsvInput.click()
    }
  }

  onTradeWaltzDialogClosed() {
    this.fetchBarcodesList(this.dataSearch)
  }

  setAssignedClientIds() {
    this.assignedClientIds = this.checkedClientIds
    this.isShowClients = false
  }

  async downloadCSV() {
    this.loading = true
    const projectId = this.getProject?.id || ''
    const barcodeType = this.barcodeType || ''
    try {
      if (!projectId || !barcodeType) {
        openMessage(this.$t('barcodeImport.please_select_type'), 'error')
      } else {
        const data = await getBarcodeCSVExample(projectId, barcodeType)
        saveCSVText(data, barcodeType)
      }
    } catch (error) {
      openMessage(error as string, 'error')
    } finally {
      this.loading = false
    }
    return true
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    await this.fetchBarcodesList(this.dataSearch)
  }
}
</script>
<style>
.el-table .error-row {
  background-color: #ffbbbb !important;
}
.el-table .completed-row {
  background-color: #e0e0e0 !important;
}

.el-table .warning-row {
  background-color: rgba(255, 255, 135, 0.836) !important;
}
</style>
<style lang="scss">
.barcode-group-header {
  align-items: center;
  .el-button--mini {
    padding-left: 0px;
    padding-right: 0px;
    span {
      font-size: 10px;
      font-weight: 700;
    }
  }
  .el-button--small {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.w-22-5 {
  width: 90px;
}
.w-16-5 {
  width: 66px;
}
.w-15 {
  width: 60px;
}
.barcode-selection {
  width: 100%;
  padding-right: 16px;
}
@media (max-width: 1279px) {
  .barcode-group-header {
    .header-container {
      &__left {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 1439px) and (min-width: 1280px) {
  .barcode-group-header {
    align-items: flex-start;
    justify-content: space-between;
    .header-container {
      &__right {
        flex-wrap: wrap;
        width: 240px;
        flex-grow: 0;
        .barcode-selection {
          width: 238px;
          margin-bottom: 12px;
          padding-right: 0px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
.header-container-wrapper {
  background-color: $body-bg;
}

.fixed-table:deep() {
  height: calc(100vh - 100px);
  min-width: 700px;

  &.table-content {
    overflow: auto;
    height: calc(100vh - 350px);
    min-height: 200px;
  }
  &.el-table {
    &::before {
      height: 0px;
    }
    &__header-wrapper {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 10;
    }
  }
}

.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 500px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
  .el-upload-dragger {
    width: 390px !important;
  }
  .el-icon {
    display: inline;
    & svg {
      height: 40px;
      width: 40px;
    }
  }
}
.client-dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 1000px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}
</style>
